@tailwind base;
@tailwind components;
@tailwind utilities;

/*@media (prefers-color-scheme: dark) {*/
/*  html {*/
/*    color-scheme: dark;*/
/*  }*/
/*}*/

html,
body {
  height: 100%;
}

main > footer {
  position: sticky;
  top: 10vh;
}

@supports (font: -apple-system-body) and (-webkit-appearance: none) {
  img[loading='lazy'] {
    clip-path: inset(0.6px);
  }
}

a,
input,
button {
  @apply focus-visible:outline-none focus-visible:ring-2 focus-visible:ring-neutral-400 focus-visible:ring-offset-2 focus-visible:ring-offset-neutral-50 dark:focus-visible:ring-neutral-600 dark:focus-visible:ring-offset-neutral-900;
}

/*THIS IS THE STUFF*/

.scroll-section {
  scroll-snap-type: y mandatory;
  overflow-y: auto;
  position: relative;
  top: 70vh;
  height: 50vh;
  z-index: 2;
}

.scroll-anim {
  opacity: 0;
  transform: translateY(50px);
  animation: slide-up 0.5s ease-out forwards;
}

@keyframes slide-up {
  to {
    opacity: 1;
    transform: translateY(0);
  }
}

.scroll-content {
  height: 200%;
}

.hidden-scroll-bar {
  -ms-overflow-style: none; /* IE and Edge */
  scrollbar-width: none; /* Firefox */
}

.hidden-scroll-bar::-webkit-scrollbar {
  display: none;
}

/* SLIDES */
.carousel {
  --slide-spacing: 1rem;
  --slide-size: 100%;
}

.carousel__viewport {
  overflow: hidden;
}
.carousel__container {
  backface-visibility: hidden;
  display: flex;
  touch-action: pan-y;
  margin-left: calc(var(--slide-spacing) * -1);
}
.carousel__slide {
  flex: 0 0 var(--slide-size);
  min-width: 0;
  padding-left: var(--slide-spacing);
  position: relative;
}
.carousel__slide__img {
  display: block;
  /*height: var(--slide-height);*/
}

.carousel__button {
  -webkit-appearance: none;
  background-color: transparent;
  touch-action: manipulation;
  display: inline-flex;
  text-decoration: none;
  cursor: pointer;
  border: 0;
  padding: 0;
  margin: 0;
}

.carousel__dot {
  -webkit-appearance: none;
  background-color: transparent;
  touch-action: manipulation;
  display: inline-flex;
  text-decoration: none;
  cursor: pointer;
  border: 0;
  padding: 0;
  margin: 0;
}

.carousel__dots {
  z-index: 10;
  position: relative;
  left: 0;
  right: 0;
  /*bottom: -40px;*/
  display: flex;
  justify-content: center;
  align-items: center;
}
.carousel__dot {
  min-width: 1.4rem;
  display: flex;
  align-items: center;
  margin-right: 0.75rem;
  margin-left: 0.75rem;
}
.carousel__dot:after {
  background: darkgray;
  width: 100%;
  height: 0.3rem;
  content: '';
}
.carousel__dot--selected:after {
  background: gray;
}

.dashed__list > li::before {
  content: '– ';
}

@media (min-width: 640px) {
  .sticky-button {
    display: none;
  }
}
